import React, { useState } from "react"
import { Link } from "gatsby"
import CookieModal from "./cookieModal"
import Cookies from "js-cookie"

const Footer = props => {
  const isCookieSet = Cookies.get("gatsby-gdpr-google-analytics")

  const [showModal, setShowModal] = useState(!isCookieSet)

  const handleClick = () => {
    console.log("click")
    setShowModal(true)
  }

  console.log("showModal", showModal)

  //get the actual year and display it in real time on client side
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer id="colophon" className="site-footer">
      <div className="site-info">
        <p>© Motivé par l’Essentiel {year}. Tous droits réservés.</p>
        <p>
          <Link to={"/cgv"}>CGV</Link> ·{" "}
          <Link to={"/confidentialite"}>Confidentialité</Link> ·{" "}
          <button className={"footer-link"} onClick={handleClick}>
            Préférences cookies
          </button>
        </p>
      </div>
      <CookieModal showModal={showModal} setShowModal={setShowModal} />
    </footer>
  )
}

export default Footer
