import React from "react"

const loaderStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  background: "rgba(255,255,255,0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

const Loader = props => {
  return (
    <div style={loaderStyle}>
      <svg width="51px" height="50px" viewBox="0 0 51 50">
        <rect y="0" width="13" height="50" fill="#787878">
          <animate
            attributeName="height"
            values="50;10;50"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            values="0;20;0"
            begin="0s"
            dur="1s"
            repeatCount="indefinite"
          />
        </rect>

        <rect x="19" y="0" width="13" height="50" fill="#443451">
          <animate
            attributeName="height"
            values="50;10;50"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            values="0;20;0"
            begin="0.2s"
            dur="1s"
            repeatCount="indefinite"
          />
        </rect>

        <rect x="38" y="0" width="13" height="50" fill="#f15c26">
          <animate
            attributeName="height"
            values="50;10;50"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            values="0;20;0"
            begin="0.4s"
            dur="1s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  )
}

export default Loader
