import React, { useEffect } from "react"
import Cookies from "js-cookie"

const CookieModal = ({ showModal, setShowModal }) => {
  const handleAcceptCookies = () => {
    Cookies.set("gatsby-gdpr-google-analytics", "true", { expires: 365 })
    setShowModal(false)
    document.body.style.overflow = "auto"
  }

  const handleRejectCookies = () => {
    Cookies.set("gatsby-gdpr-google-analytics", "false", { expires: 365 })
    setShowModal(false)
    document.body.style.overflow = "auto"
  }

  // if window is not undefined and showModal is true, set overflow to hidden
  useEffect(() => {
    if (typeof window !== "undefined" && showModal) {
      document.body.style.overflow = "hidden"
    }
  }, [showModal])

  return (
    showModal && (
      <div className={"modal-lightbox"}>
        <div className={"modal-content"}>
          <h2 style={{ fontSize: "24px", lineHeight: "1.2" }}>
            Préférences en matière de cookies
          </h2>
          <p>
            Nous utilisons des cookies d'analyse (Google Analytics) pour
            améliorer votre expérience. Acceptez-vous ces cookies ?
          </p>
          <button className={"modal-accept"} onClick={handleAcceptCookies}>
            Accepter
          </button>
          <button className={"modal-decline"} onClick={handleRejectCookies}>
            Refuser
          </button>
        </div>
      </div>
    )
  )
}

export default CookieModal
